<template>
  <div class="landing">
    <public-header :button="{url: 'login', text: $t('landing.merchant_signin')}"/>
    <hero/>
    <div class="container" id="platform">
      <div class="landing__platform">
        <img src="/images/landing/img1.png" class="landing__platform-img"/>
        <div class="landing__platform-cont">
          <h2>{{$t('landing.platform')}}</h2>
          <div class="landing__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.our_goal')}}</span>
          </div>
          <div class="landing__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.we_support')}}</span>
          </div>
          <div class="landing__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.we_provide')}}</span>
          </div>
          <div class="landing__boxopt mg-t-30">
            <div class="landing__boxopt-cont">
              <h4>{{$t('landing.ecommerce')}}</h4>
              <p>{{$t('landing.ecommerce_txt')}}</p>
            </div>
          </div>
          <div class="landing__boxopt">
            <div class="landing__boxopt-cont">
              <h4>{{$t('landing.eflifestyle')}}</h4>
              <p>{{$t('landing.eflifestyle_txt')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="landing__features">
      <h3>{{$t('landing.features')}}</h3>
      <div class="container">
        <div class="flex align-center justify-around landing__features-cont">
          <div class="landing__feature">
            <img src="/images/landing/icon1.png"/>
            <p>{{$t('landing.promo_1_title')}}</p>
            <span>{{$t('landing.promo_1_txt')}}</span>
          </div>
          <div class="landing__feature">
            <img src="/images/landing/icon2.png"/>
            <p>{{$t('landing.promo_2_title')}}</p>
            <span>{{$t('landing.promo_2_txt')}}</span>
          </div>
          <div class="landing__feature">
            <img src="/images/landing/icon3.png"/>
            <p>{{$t('landing.promo_3_title')}}</p>
            <span>{{$t('landing.promo_3_txt')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="landing__partner" id="partner">
      <div class="container">
        <h2>{{$t('landing.become_parner')}}</h2>
        <div class="landing__option">
          <img src="/images/landing/arrow.png"/>
          <span>{{$t('landing.partner_opt1')}}</span>
        </div>
        <div class="landing__option">
          <img src="/images/landing/arrow.png"/>
          <span>{{$t('landing.partner_opt2')}}</span>
        </div>
        <div class="landing__partner-cont">
          <div class="landing__partner-box">
            <h4>{{$t('landing.benefits')}}</h4>
            <div class="landing__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('landing.benefits_1')}}</span>
            </div>
            <div class="landing__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('landing.benefits_2')}}</span>
            </div>
            <div class="landing__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('landing.benefits_3')}}</span>
            </div>
            <div class="landing__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('landing.benefits_4')}}</span>
            </div>
            <div class="landing__option2">
              <img src="/images/landing/check.png"/>
              <span>{{$t('landing.benefits_5')}}</span>
            </div>
            <div class="flex justify-center">
              <router-link to="/join" class="btn btn--red mg-t-30 w-150">{{$t('landing.join_now')}}</router-link>
            </div>
          </div>
          <img src="/images/landing/img2.png" class="landing__partner-img"/>
        </div>
      </div>
    </div>
    <div class="landing__how">
      <div class="container">
        <h3>{{$t('landing.how')}}</h3>
        <div class="landing__steps">
          <div class="landing__steps-line"/>
          <div class="landing__step">
            <img src="/images/step1.png"/>
            <h4>{{$t('landing.step1')}}</h4>
            <p>{{$t('landing.step1_txt')}}</p>
          </div>
          <div class="landing__step">
            <img src="/images/step2.png"/>
            <h4>{{$t('landing.step2')}}</h4>
            <p>{{$t('landing.step2_txt')}}</p>
          </div>
          <div class="landing__step">
            <img src="/images/step3.png"/>
            <h4>{{$t('landing.step3')}}</h4>
            <p>{{$t('landing.step3_txt')}}</p>
          </div>
          <div class="landing__step">
            <img src="/images/step4.png"/>
            <h4>{{$t('landing.step4')}}</h4>
            <p>{{$t('landing.step4_txt')}}</p>
          </div>
          <div class="landing__step">
            <img src="/images/step5.png"/>
            <h4>{{$t('landing.step5')}}</h4>
            <p>{{$t('landing.step5_txt')}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container" id="contact">
      <div class="landing__platform">
        <img src="/images/landing/img3.png" class="landing__platform-img"/>
        <div class="landing__platform-cont">
          <h2>{{$t('landing.contact')}}</h2>
          <h4>{{$t('landing.contact_sub')}}</h4>
          <h6>{{$t('landing.contact_text1')}}</h6>
          <h6>{{$t('landing.contact_text2')}}</h6>
        </div>
      </div>
    </div>
    <public-footer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicHeader from '@/components/publicHeader/publicHeader'
import footer from '@/components/footer/footer'
import hero from '@/components/hero/hero'

export default {
  name: 'Landing',
  components: {
    publicHeader,
    hero,
    'public-footer': footer
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthed',
      me: 'auth/me'
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
