<template>
  <div class="hero">
    <div class="hero__top" id="welcome">
      <div class="hero__hero container">
        <div class="hero__hero-text">
          <h3>{{$t('landing.welcome')}}</h3>
          <h5>{{$t('landing.improving_life')}}</h5>
          <h6>{{$t('landing.key_goals')}}</h6>
          <div class="hero__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.help_prosper')}}</span>
          </div>
          <div class="hero__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.bring_online')}}</span>
          </div>
          <div class="hero__option">
            <img src="/images/landing/arrow.png"/>
            <span>{{$t('landing.provide_choices')}}</span>
          </div>

          <div class="flex direction-column align-center">
            <h2>{{$t('landing.download')}}</h2>
            <div class="hero__qr">
              <img src="/images/landing/qrcode.jpeg"/>
            </div>
            <div class="hero__download">
              <a href="https://apps.apple.com/hk/app/lifes/id1576063200" target="_blank"><img src="/images/landing/appstore.png"/></a>
              <a href="https://play.google.com/store/apps/details?id=com.lifes" target="_blank"><img src="/images/landing/playstore.png"/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
